import React from "react";
import { ToastProvider } from 'react-toast-notifications';

import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";


ReactDOM.render(
  <ToastProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ToastProvider>,
  document.getElementById("root")
);
